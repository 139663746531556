
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { LocalisationUi, MeasureTableResume } from "@/domain";

import { IMeasureTableService } from "@/services";

import Loading from "@/views/Loading.vue";
import ZoneSelection from "@/components/Core/ExamReports/Historic/ZoneSelection.vue";
import HistoricDataTable from "@/components/Core/ExamReports/Historic/Dashboard/HistoricDataTable.vue";

@Component({
  components: {
    Loading,
    HistoricDataTable,
    ZoneSelection,
  },
})
export default class HistoricDashboard extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  fetching: boolean = false;
  removing: boolean = false;
  tablesResume: MeasureTableResume[] = [];
  localisation: LocalisationUi = {
    powerPlantId: null,
    nuclearUnitId: null,
    systemId: null,
    equipmentId: null,
    zoneId: null,
  };

  async fetch() {
    try {
      this.fetching = true;
      const tables = await this.measureTableService.getResume({
        powerPlantId: this.localisation.powerPlantId,
        nuclearUnitId: this.localisation.nuclearUnitId,
        systemId: this.localisation.systemId,
        equipmentId: this.localisation.equipmentId,
        zoneId: this.localisation.zoneId,
      });
      this.tablesResume = _.filter(tables, (t) => t.containsMeasures);
    } finally {
      this.fetching = false;
    }
  }

  async remove(id: string) {
    try {
      this.removing = true;
      await this.measureTableService.remove(id);
      const index = _.findIndex(this.tablesResume, (t) => t.id == id);
      if (index != -1) {
        this.tablesResume.splice(index, 1);
      }
    } finally {
      this.removing = false;
    }
  }
}
