
import { Component, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";
import _ from "lodash";

import { MeasureTableResume } from "@/domain";
import { TableOrigin } from "prometheus-synced-ui";

import HistoricPreview from "@/components/Core/ExamReports/Historic/Dashboard/HistoricPreview.vue";
import HistoricFilter from "@/components/Core/ExamReports/Historic/Dashboard/HistoricFilter.vue";
import HistoricActions from "@/components/Core/ExamReports/Historic/Dashboard/HistoricActions.vue";

@Component({
  components: {
    HistoricPreview,
    HistoricFilter,
    HistoricActions,
  },
  filters: {
    prettyDate(date: Date | null): string {
      return date != null ? lightFormat(date, "dd/MM/yyyy") : "";
    },
    prettyOrigin(origin: TableOrigin): string {
      return origin == TableOrigin.technisonic ? "Technisonic" : "EDF";
    },
  },
  data: () => {
    return {
      TableOrigin,
    };
  },
})
export default class HistoricDataTable extends Vue {
  @Prop({ required: true })
  tablesResume!: MeasureTableResume[];

  @Prop({ required: true })
  removing!: boolean;

  get headers() {
    return [
      {
        text: "N° de Visite",
        align: "center",
        sortable: true,
        value: "visit.number",
      },
      {
        text: "Version",
        align: "center",
        sortable: true,
        value: "version",
      },
      {
        text: "Date",
        align: "center",
        sortable: true,
        value: "date",
      },
      {
        text: "Colonnes x Lignes",
        align: "center",
        sortable: true,
        value: "dimensions",
      },
      {
        text: "Origine",
        align: "center",
        sortable: true,
        value: "origin",
      },
      {
        text: "Création",
        align: "center",
        sortable: true,
        value: "created",
      },
      {
        text: "Dernière mise à jour",
        align: "center",
        sortable: true,
        value: "lastUpdate",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ];
  }

  get comparaisonUrl() {
    return this.selected.length == 2
      ? {
          name: "historic-comparaison",
          params: {
            firstTableId: this.selected[0].id,
            secondTableId: this.selected[1].id,
            powerPlantId:
              this.selected[0].powerPlant.id == this.selected[1].powerPlant.id
                ? this.selected[0].powerPlant.id
                : null,
            nuclearUnitId:
              this.selected[0].nuclearUnit.id == this.selected[1].nuclearUnit.id
                ? this.selected[0].nuclearUnit.id
                : null,
            systemId:
              this.selected[0].system.id == this.selected[1].system.id
                ? this.selected[0].system.id
                : null,
            equipmentId:
              this.selected[0].equipment.id == this.selected[1].equipment.id
                ? this.selected[0].equipment.id
                : null,
            zoneId:
              this.selected[0].zone.id == this.selected[1].zone.id
                ? this.selected[0].zone.id
                : null,
            fromDashboard: "true",
          },
        }
      : null;
  }

  get tablesFiltered(): MeasureTableResume[] {
    if (this.visitSearch != null) {
      return _.chain(this.filteredByEndDate)
        .filter((t) =>
          t.visit.number.toUpperCase().includes(this.visitSearch!.toUpperCase())
        )
        .orderBy((t) => [t.visit.number, t.version])
        .value();
    } else {
      return _.orderBy(this.filteredByEndDate, (t) => [
        t.visit.number,
        t.version,
      ]);
    }
  }

  get filteredByEndDate(): MeasureTableResume[] {
    if (this.endDateSearch != null) {
      return _.filter(
        this.filteredByStartDate,
        (t) => t.date != null && t.date < this.endDateSearch!
      );
    } else {
      return this.filteredByStartDate;
    }
  }

  get filteredByStartDate(): MeasureTableResume[] {
    if (this.startDateSearch != null) {
      return _.filter(
        this.filteredByColumns,
        (t) => t.date != null && t.date > this.startDateSearch!
      );
    } else {
      return this.filteredByColumns;
    }
  }

  get filteredByColumns(): MeasureTableResume[] {
    if (this.columnsSearch != null) {
      return _.filter(
        this.filteredByLines,
        (t) => t.columnSize == this.columnsSearch
      );
    } else {
      return this.filteredByLines;
    }
  }

  get filteredByLines(): MeasureTableResume[] {
    if (this.linesSearch != null) {
      return _.filter(this.tablesResume, (t) => t.lineSize == this.linesSearch);
    } else {
      return this.tablesResume;
    }
  }

  selected: MeasureTableResume[] = [];
  previewDialog: boolean = false;
  tablePreview: MeasureTableResume | null = null;
  visitSearch: string | null = null;
  startDateSearch: Date | null = null;
  endDateSearch: Date | null = null;
  columnsSearch: number | null = null;
  linesSearch: number | null = null;
  removedId: string | null = null;

  isChecked(id: string): boolean {
    return _.findIndex(this.selected, (s) => s.id == id) != -1;
  }

  cannotCheck(item: MeasureTableResume): boolean {
    return this.selected.length == 2 && !this.isChecked(item.id);
  }

  show(table: MeasureTableResume) {
    this.tablePreview = table;
    this.previewDialog = true;
  }

  onSelect(item: MeasureTableResume, isSelected: boolean) {
    if (!this.cannotCheck(item)) {
      if (!isSelected && this.selected.length < 2) {
        this.selected.push(item);
      } else if (isSelected) {
        const index = _.findIndex(this.selected, (s) => s.id == item.id);
        if (index != -1) {
          this.selected.splice(index, 1);
        }
      }
    }
  }

  remove(id: string) {
    this.removedId = id;
    this.$emit("remove", id);
  }
}
