
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";
import { LITE_FEATURE } from "@/domain";

import { Feature } from "vue-feature-flags";

@Component({
  components: {
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class HistoricActions extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Prop({ required: true })
  isDeletable!: boolean;

  @Prop({ required: true })
  removing!: boolean;
}
