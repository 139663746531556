
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { toNumber } from "@/tools";

import { DateSelector } from "prometheus-synced-ui";

@Component({
  components: {
    DateSelector,
  },
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class HistoricDataTable extends Vue {
  @Prop({ required: true })
  visitNumber!: string | null;

  @Prop({ required: true })
  startDate!: Date | null;

  @Prop({ required: true })
  endDate!: Date | null;

  @Prop({ required: true })
  columns!: number | null;

  @Prop({ required: true })
  lines!: number | null;

  updateColumns(value: number) {
    this.$emit("update:columns", value == 0 ? null : value);
  }

  updateLines(value: number) {
    this.$emit("update:lines", value == 0 ? null : value);
  }

  reset() {
    this.$emit("update:visitNumber", null);
    this.$emit("update:startDate", null);
    this.$emit("update:endDate", null);
    this.$emit("update:columns", null);
    this.$emit("update:lines", null);
  }
}
